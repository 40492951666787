import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import ContactBar from "../../components/ContactBar";
import SingleImage from "../../library/SingleImage";
import Layout from "../../components/Layout";

const SchwangerschaftsbeschwerdenPage = (props) => (
  <Layout location={props.location}>
    <div className="page offer">
      <Helmet>
        <title>Schwangerschafts-Begleitung</title>
        <meta
          name="description"
          content="Viele Beschwerden in der Schwangerschaft sind oft mit herkömmlichen Medikamenten schlecht zu behandeln"
        />
        <meta property="og:title" content="Schwangerschaftsbeschwerden" />
        <meta
          property="og:description"
          content="Viele Beschwerden in der Schwangerschaft sind oft mit herkömmlichen Medikamenten schlecht zu behandeln"
        />
      </Helmet>
      <ContactBar className="large-screen" />
      <div className="container">
        <h1>Schwangerschafts-Begleitung</h1>
        <SingleImage image={props.data.schwangerschaftsbeschwerden.fluid} />
        <p>
          In kaum einer anderen Zeit verändert sich der weibliche Körper stärker
          als während der Schwangerschaft. Nicht nur die körperlichen
          Veränderungen, sondern auch die hormonelle Umstellung können eine
          Vielzahl von Beschwerden auslösen.
        </p>
        <p>
          Zu Beginn der Schwangerschaft leiden viele Frauen unter{" "}
          <strong>Übelkeit und starker Müdigkeit</strong>. Der Auslöser für die
          starke Übelkeit ist noch nicht definitiv geklärt. Einige Theorien
          führen es auf das erhöhte Schwangerschaftshormon hCG zurück, andere
          erklären es mit der hormonellen Umstellung im Stammhirn, in dessen
          Teil sich das Brechzentrum befindet. Die Müdigkeit führt man auf den
          Aufbau des fetalen Blutkreislaufs zurück. In dieser Zeit leidet die
          Frau unter einem relativen Blutmangel, das hat eine starke Müdigkeit
          zur Folge.
        </p>
        <p>
          Die strukturellen Veränderungen und die Gewichtszunahme zeigen sich
          schnell in{" "}
          <strong>Schmerzen am Rücken, dem Becken oder der Symphyse</strong>.
        </p>
        <p>
          <strong>Wassereinlagerungen (Ödeme)</strong> in den Beinen oder am
          ganzen Körper können die Beweglichkeit und das Wohlbefinden stark
          einschränken. Gründe für diese Ödeme sind vielfältig. Oft liegt es am
          erhöhten Wasseranteil im Körper durch das gesteigerte Blutvolumen der
          schwangeren Frau. Oder eine Venenschwäche kann das Blut nicht mehr
          adäquat zum Herzen zurückpumpen und versackt in den Beinen.{" "}
        </p>
        <p>
          Liegt Ihr Kind in der 34. SSW oder später noch immer oder wieder in
          der
          <strong> Steißlage</strong>, kann die Anwendung von Moxa über 7d Ihr
          Kind zur Drehung animieren.
        </p>
        <p>
          Weitere schwangerschaftsbedingte Beschwerden können{" "}
          <strong>
            gehäufter Intimpilz, Schlafstörungen, Verdauungsbeschwerden oder
            Karpaltunnelsyndrom (Schmerzen der Hände)
          </strong>{" "}
          sein.
        </p>
        <p>
          Da während der Schwangerschaft viele Arzneimittel nicht erlaubt sind,
          stellt die TCM eine sehr gute und hilfreiche Alternative dar.
        </p>
        <div className="cta">
          <p>Buchen Sie noch heute einen Termin!</p>
          <a
            className="button"
            href="https://www.coachfrog.ch/de/fachperson/paola-hoffmann/?bookingOpen=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            Online buchen
          </a>
        </div>
      </div>
    </div>
  </Layout>
);

export default SchwangerschaftsbeschwerdenPage;

export const query = graphql`
  query SchwangerschaftImageQuery {
    schwangerschaftsbeschwerden: imageSharp(
      fluid: { originalName: { regex: "/schwangerschaft/" } }
    ) {
      fluid(maxHeight: 580, cropFocus: ENTROPY) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
